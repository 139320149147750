import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const GlobalHeader = () => {
  const navigate = useNavigate();

  return (
    <Header className="border-b border-secondary-100 select-none">
      <div className="flex items-end">
        <h5
          className="text-xl text-primary-900 cursor-pointer"
          onClick={() => navigate("/dashboard")}
        >
          클릭메이트
        </h5>
        <span className="text-sm ml-3">마스터 관리자</span>
      </div>
      <Tools>
        <div className="flex items-center ml-5 cursor-pointer">
          <span className="user-name ml-2">관리자</span>
        </div>
      </Tools>
    </Header>
  );
};

const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background-color: white;
  z-index: 999;

  padding: 0 24px;
`;

const Tools = styled.div`
  display: flex;
  align-items: center;
`;

export default GlobalHeader;
